import React from 'react';

const UxUiUserResearch = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="49"
    height="48"
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.49786 23.5666C5.30055 23.5666 5.11138 23.4882 4.97186 23.3487C4.83234 23.2091 4.75391 23.02 4.75391 22.8227V11.6932C4.75391 11.4959 4.83234 11.3063 4.97186 11.1668C5.11138 11.0273 5.30055 10.9492 5.49786 10.9492C5.69517 10.9492 5.88434 11.0273 6.02386 11.1668C6.16337 11.3063 6.24181 11.4959 6.24181 11.6932V22.8227C6.24181 23.02 6.16337 23.2091 6.02386 23.3487C5.88434 23.4882 5.69517 23.5666 5.49786 23.5666Z"
      fill="#475467"
    />
    <path
      d="M9.99102 23.574C9.79371 23.574 9.60454 23.4955 9.46503 23.356C9.32551 23.2165 9.24707 23.0273 9.24707 22.83V7.95098C9.24707 7.75368 9.32551 7.56414 9.46503 7.42462C9.60454 7.2851 9.79371 7.20703 9.99102 7.20703C10.1883 7.20703 10.3775 7.2851 10.517 7.42462C10.6565 7.56414 10.735 7.75368 10.735 7.95098V22.83C10.735 23.0273 10.6565 23.2165 10.517 23.356C10.3775 23.4955 10.1883 23.574 9.99102 23.574Z"
      fill="#475467"
    />
    <path
      d="M14.4842 23.564C14.2869 23.564 14.0977 23.4856 13.9582 23.3461C13.8187 23.2066 13.7402 23.0174 13.7402 22.8201V11.869C13.7402 11.6716 13.8187 11.4825 13.9582 11.343C14.0977 11.2034 14.2869 11.125 14.4842 11.125C14.6815 11.125 14.8707 11.2034 15.0102 11.343C15.1497 11.4825 15.2281 11.6716 15.2281 11.869V22.8201C15.2208 23.015 15.1401 23.1998 15.0022 23.3377C14.8642 23.4757 14.6791 23.5567 14.4842 23.564Z"
      fill="#475467"
    />
    <path
      d="M18.9471 23.5759C18.7498 23.5759 18.5606 23.4974 18.4211 23.3579C18.2816 23.2184 18.2031 23.0292 18.2031 22.8319V15.3924C18.2031 15.1951 18.2816 15.0055 18.4211 14.866C18.5606 14.7265 18.7498 14.6484 18.9471 14.6484C19.1444 14.6484 19.3336 14.7265 19.4731 14.866C19.6126 15.0055 19.691 15.1951 19.691 15.3924V22.8319C19.691 23.0292 19.6126 23.2184 19.4731 23.3579C19.3336 23.4974 19.1444 23.5759 18.9471 23.5759Z"
      fill="#475467"
    />
    <path
      d="M23.4461 23.564C23.2488 23.564 23.0596 23.4856 22.9201 23.3461C22.7806 23.2066 22.7021 23.0174 22.7021 22.8201V11.869C22.7021 11.6716 22.7806 11.4825 22.9201 11.343C23.0596 11.2034 23.2488 11.125 23.4461 11.125C23.6434 11.125 23.8326 11.2034 23.9721 11.343C24.1116 11.4825 24.1901 11.6716 24.1901 11.869V22.8201C24.1901 23.0174 24.1116 23.2066 23.9721 23.3461C23.8326 23.4856 23.6434 23.564 23.4461 23.564Z"
      fill="#475467"
    />
    <path
      d="M27.9393 23.568C27.742 23.568 27.5528 23.4896 27.4133 23.3501C27.2737 23.2106 27.1953 23.0214 27.1953 22.8241V18.8065C27.1953 18.6091 27.2737 18.42 27.4133 18.2805C27.5528 18.1409 27.742 18.0625 27.9393 18.0625C28.1366 18.0625 28.3257 18.1409 28.4653 18.2805C28.6048 18.42 28.6832 18.6091 28.6832 18.8065V22.8241C28.6832 23.0214 28.6048 23.2106 28.4653 23.3501C28.3257 23.4896 28.1366 23.568 27.9393 23.568Z"
      fill="#475467"
    />
    <path
      d="M43.5281 47.9979C43.1521 48.0022 42.7794 47.9304 42.4318 47.7872C42.0841 47.6441 41.7687 47.4324 41.5047 47.1646L27.6668 33.327C27.4579 33.1116 27.341 32.8239 27.3398 32.5239C27.3394 32.2147 27.456 31.9168 27.6668 31.6906L31.7738 27.5839C31.9912 27.3677 32.2852 27.2461 32.5918 27.2461C32.8985 27.2461 33.1932 27.3677 33.4106 27.5839L47.2478 41.4513C47.7497 41.9999 48.0281 42.7162 48.0281 43.4598C48.0281 44.2033 47.7497 44.92 47.2478 45.4686L45.5216 47.1646C45.2632 47.4315 44.9534 47.6433 44.6106 47.7865C44.2678 47.9298 43.8996 48.0016 43.5281 47.9979ZM28.9469 32.5239L42.5756 46.1232C42.7002 46.2491 42.8488 46.3491 43.0122 46.4174C43.1757 46.4856 43.3509 46.5209 43.5281 46.5209C43.7052 46.5209 43.8804 46.4856 44.0439 46.4174C44.2074 46.3491 44.356 46.2491 44.4805 46.1232L46.206 44.3973C46.332 44.2728 46.432 44.1245 46.5003 43.961C46.5685 43.7976 46.6041 43.622 46.6041 43.4449C46.6041 43.2677 46.5685 43.0925 46.5003 42.929C46.432 42.7656 46.332 42.6173 46.206 42.4928L32.6071 28.8931L28.9469 32.5239Z"
      fill="#475467"
    />
    <path
      d="M28.9759 32C28.8116 31.9927 28.6563 31.9242 28.5407 31.8079L26.1214 29.4057C26.0341 29.29 25.9912 29.147 26.0015 29.0028C26.0118 28.8586 26.0746 28.7229 26.1775 28.6207C26.2805 28.5185 26.4168 28.4565 26.562 28.4462C26.7072 28.436 26.8513 28.4779 26.9678 28.5647L28.8791 30.4385L30.4277 28.901L28.5407 27.0272C28.4821 26.9712 28.4354 26.9042 28.4036 26.8299C28.3718 26.7556 28.3552 26.6757 28.3552 26.595C28.3552 26.5143 28.3718 26.4344 28.4036 26.3601C28.4354 26.2858 28.4821 26.2185 28.5407 26.1625C28.6565 26.0578 28.8075 26 28.9641 26C29.1207 26 29.2712 26.0578 29.387 26.1625L31.8063 28.5647C31.8657 28.6154 31.9141 28.6782 31.9474 28.7485C31.9808 28.8189 31.9985 28.8954 32 28.9732C31.9989 29.0545 31.9813 29.1349 31.948 29.2092C31.9148 29.2836 31.8663 29.3504 31.8063 29.4057L29.387 31.8079C29.2775 31.9188 29.1318 31.9869 28.9759 32Z"
      fill="#475467"
    />
    <path
      d="M16.3906 32.7339C13.6964 32.7379 11.0429 32.0766 8.66558 30.809C6.28824 29.5413 4.26064 27.7066 2.76264 25.4673C1.26464 23.2279 0.343107 20.6534 0.0788917 17.9722C-0.185324 15.291 0.216497 12.5858 1.24858 10.0971C2.28067 7.60842 3.91072 5.41324 5.99491 3.70593C8.0791 1.99863 10.553 0.832167 13.1961 0.310198C15.8393 -0.21177 18.5704 -0.0734537 21.1471 0.713415C23.7239 1.50028 26.0663 2.91125 27.9669 4.82077C30.2468 7.1113 31.7973 10.0255 32.4234 13.1961C33.0495 16.3666 32.7235 19.6515 31.4855 22.6368C30.2474 25.622 28.1532 28.1741 25.467 29.9709C22.7808 31.7678 19.6224 32.7291 16.3906 32.7339ZM16.3906 1.51767C13.9426 1.51715 11.5329 2.12075 9.37394 3.27475C7.21501 4.42874 5.37399 6.09758 4.01443 8.13332C2.65487 10.1691 1.81871 12.5088 1.57987 14.9451C1.34104 17.3815 1.70661 19.8389 2.64495 22.0999C3.58328 24.3609 5.06528 26.3556 6.959 27.9069C8.85272 29.4582 11.0998 30.5183 13.5013 30.9932C15.9028 31.468 18.3841 31.3429 20.7257 30.6292C23.0674 29.9154 25.1968 28.6351 26.9251 26.9014C29.7145 24.1112 31.282 20.3272 31.282 16.3818C31.282 12.4364 29.7145 8.65247 26.9251 5.86223C25.5432 4.47603 23.9015 3.37625 22.0938 2.6256C20.2861 1.87496 18.348 1.4883 16.3906 1.48788V1.51767Z"
      fill="#475467"
    />
  </svg>
);

export default UxUiUserResearch;
